.sideNavMenu {
  height: 100vh;
  background-color: #fff;
  width: 225px;

  
}

.sideNavMenu :global(.ant-menu) {
    background-color: #fff;
  
}

.sideNavMenu :global(.ant-menu-item:hover) , .sideNavMenu :global(.ant-menu-submenu-title:hover) {
  background-color: rgba(38,141,221,.1);
  /* background-color: #997733; */
}

.sideNavMenuBrandName {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
 
  margin-bottom: 00px;

  font-weight: 500;
  font-size: 18px;
}