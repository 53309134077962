.sideNav {
  height: 100vh;
  background-color: #1665C0;
  display: flex;
  /* width: 300px; */
}

.sideNavMenu {
  height: 100vh;
  background-color: #fff;
  width: 225px;
}

.sideNavMenu :global(.ant-menu) {
    background-color: #fff;
  
}

.sideNavMenu :global(.ant-menu-item:hover) , .sideNavMenu :global(.ant-menu-submenu-title:hover) {
  background-color: rgba(38,141,221,.1);
  /* background-color: #997733; */
}

/* .sideNavMenu .ant-menu-item:hover, 
.sideNavMenu .ant-menu-submenu-title:hover {
  background-color: none;
  color: blue;
} */